<template>
  <modal-ui ref="annotationModal" size="l" prevent-closure full-height @close="close">
    <template #default>
      <ad-annotation
          ref="annotation"
          :file="annotationFile"
          :initial-page="initialPage"
          :is-loading="isLoading"
          loader-text="Отправка данных"
          @change="change"
          @download="downloadFile"
          @close="close"
          @apply="apply"
      />

      <edit-file-confirm-modal
          ref="confirmModal"
          :file-name="fileNameType"
          @cancel="cancel"
          @discard="discard"
          @save="save"
      />
    </template>
  </modal-ui>
</template>

<script>
import {defineAsyncComponent, defineComponent} from 'vue';
import EditFileConfirmModal from '@/components/modals/File/EditFileConfirmModal.vue';
import viewFile from '@/mixins/viewFile';
import downloadFile from '@/mixins/downloadFile';
import {mapActions} from 'vuex';
import {DOC_FILES_GET} from '@/configs/endPoints';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import ModalUi from '@/components/ui/ModalUi.vue';
import {FileApiService} from '@/services/api/file-api.service';

export default defineComponent({
  name: "EditFileModal",
  components: {
    ModalUi,
    AdAnnotation: defineAsyncComponent(() => import('@alphadoc/alphadoc-annotation').then(({AdAnnotation}) => AdAnnotation)),
    EditFileConfirmModal,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    pagesCount: {
      type: Number,
      required: true,
    },
    initialPage: {
      type: Number,
      default: 1,
    }
  },
  mixins: [viewFile, downloadFile],
  data() {
    return {
      isDirty: false,
      isLoading: false,
    };
  },
  computed: {
    annotationFile() {
      return {
        icon: this.fileIcon,
        name: this.fileName,
        type: this.fileType,
        revision: this.fileRevision,
        pages: this.filePages,
      };
    },
  },
  methods: {
    ...mapActions(['refreshPartlyPreviewAction']),
    show() {
      this.$refs.annotationModal.show();
    },
    hide() {
      this.$refs.annotationModal.hide();
      this.isDirty = false;
      this.isLoading = false;
    },
    change(isDirty) {
      this.isDirty = isDirty;
    },
    cancel() {
      this.$refs.confirmModal.hide();
    },
    discard() {
      this.$refs.confirmModal.hide();
      this.hide();
    },
    save() {
      this.$refs.confirmModal.hide();
      const annotations = this.$refs.annotation.getAnnotations();
      this.apply(annotations);
    },
    close() {
      if (this.isLoading) {
        return;
      }

      if (this.isDirty) {
        this.$refs.confirmModal.show();
      } else {
        this.hide();
      }
    },
    async apply(annotations) {
      this.isLoading = true;

      const fileEdits = Object.entries(annotations).map(([page, svg]) => ({
        page_url: this.filePages[page - 1],
        page_edits_svg: svg
      }));

      try {
        await FileApiService.edit({
          parentTableId: this.fileParentTableId,
          parentId: this.fileParentId,
          edits: fileEdits,
          file: {
            name: this.fileNameType,
            comment: this.fileComment,
          },
        });
        this.$eventBus.$emit('table-start-reload-files');
        this.refreshPartlyPreviewAction(DOC_FILES_GET);
        this.hide();
      } catch (error) {
        this.$notify({
          title: 'Ошибка при редактировании документа',
          type: ERROR_NOTIFY_TYPE,
          text: error?.error_message || 'Неизвестная ошибка',
        });
      } finally {
        this.isLoading = false;
      }
    },
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/resolutions";

.annotation {
  height: 100%
}

/* TODO: Отказаться от перекрытия стилей */
:deep {
  .icon {
    display: block;
    width: auto;
  }

  .button {
    &._pencil {
      svg {
        fill: transparent;
      }
    }
  }

  p {
    margin-bottom: 0;
  }
}
</style>
