import axios from 'axios';
import {saveAs} from '@/services/fileSaver';
import {mapState} from 'vuex';
import {FileApiService} from '@/services/api/file-api.service';

const downloadFile = {
    data: () => ({
        disabled: false,
    }),
    computed: {
        ...mapState('server', ['storageUrl']),
    },
    methods: {
        downloadFile() {
            if (this.disabled) {
                return;
            }
            this.disabled = true;
            let extension = this.getExtension(this.file['Название']);
            let url = `${this.storageUrl}${this.file['Файл']}.${extension}`;
            axios.get(url).then(() => {
                this.createHrefDownload(url);
            }).catch(() => {
                FileApiService.addView(this.file['F_ID']).then(() => {
                    this.intervalGetFile = setInterval(() => {
                        axios.get(url).then(() => {
                            this.createHrefDownload(url);
                            clearInterval(this.intervalGetFile);
                        }).catch(() => {
                        });
                    }, 1000);
                });
            });
        },
        createHrefDownload(url) {
            try {
                let xhr = new XMLHttpRequest();
                xhr.open('GET', url);
                xhr.responseType = 'blob';

                xhr.onload = () => {
                    saveAs(url, this.fileNameDownload());
                };
                xhr.send();
            } catch (e) {
                console.error(e);
                let link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', 'download');
                link.click();
            } finally {
                this.disabled = false;
            }
        },
        getExtension(fileName) {
            return fileName.split('.').pop();
        },
        fileNameDownload() {
            return this.file['DOC_ID'] + '_' + this.file['Редакция'] + '_' + this.file['Название'];
        },
    },
};

export default downloadFile;
