import HttpService from '@/services/http';
import {
    SYSTEM_FILE_ADD_VIEW,
    SYSTEM_FILE_ADD_WITH_CONTENT,
    SYSTEM_FILE_ADD_WITH_EDITS,
    SYSTEM_FILE_EDITION_GET,
    SYSTEM_FILE_META_GET,
} from '@/configs/endPoints';
import {FILE_ATTRIBUTES} from '@/common/consts/file.consts';

export class FileApiService {
    static async upload(request) {
        const editionResponse = await HttpService.post(SYSTEM_FILE_EDITION_GET, {
            parent_table_id: request.parentTableId,
            parent_id: request.parentId,
            name: request.file.name,
        }, {}, true);

        const addResponse = await HttpService.post(SYSTEM_FILE_ADD_WITH_CONTENT, {
            parent_table_id: request.parentTableId,
            parent_id: request.parentId,
            file_name: request.file.name,
            file_size: request.file.size,
            file_content: request.file.content,
            file_edition: editionResponse.file_edition,
            comment: request.file.comment || undefined,
            attributes: FILE_ATTRIBUTES,
        }, {}, true);

        return addResponse.file_id;
    }

    static async edit(request) {
        return await HttpService.post(SYSTEM_FILE_ADD_WITH_EDITS, {
            parent_table_id: request.parentTableId,
            parent_id: request.parentId,
            file_edits: request.edits,
            file_name: request.file.name,
            comment: request.file.comment,
            attributes: FILE_ATTRIBUTES,
        }, {}, true);
    }

    static async getMeta(hash) {
        const response = await HttpService.post(SYSTEM_FILE_META_GET, {
            file_hash: hash,
        }, {}, true);

        return response.count_pages;
    }

    static async addView(id) {
        return await HttpService.post(SYSTEM_FILE_ADD_VIEW, {
            file_ids: [id],
        }, {}, true);
    }
}
