<template>
  <div :class="classWrapper">
    <div class="sidebar-content"
         v-touch:swipe="swipeHandler"
         @click="clickHandler">
      <router-link class="sidebar-logo"
                   :to="'/'"
                   data-tooltip-focus="on"
                   data-tooltip-position="right"
                   data-tooltip-size="auto"
                   data-tooltip-text-align="left"
                   :role="isCollapsedState || isHeightBaby || isHeightTiny ? 'tooltip' : ''">
        <img src="/img/logo-icon.svg?v2" alt="АЛЬФА ДОК"/>
        <span v-if="!isCollapsedState">
                    АЛЬФАДОК
                    <git-release-data class="sidebar-logo-git-release-data"/>
                </span>
        <div v-if="isCollapsedState || isHeightBaby || isHeightTiny"
             class="tooltip-aria-label">
          <git-release-data/>
        </div>
      </router-link>

      <router-link class="sidebar-link position-relative"
                   to="/commissions"
                   data-tooltip-position="bottom"
                   data-tooltip-size="auto"
                   data-tooltip-text-align="left"
                   role="tooltip">
        <commissions-icon/>
        <counter-commissions-icon-small v-if="isCollapsedState"/>
        <span class="sidebar-link-text" v-if="!isCollapsedState">Поручения</span>
        <menu-counter v-if="!isCollapsedState" getter="commissionsCountGetter"/>
        <div class="tooltip-aria-label">
          Поручения
          <div v-if="commissionsCountGetter.count > 0"
               class="small">
            Всего:&nbsp;<b>{{ commissionsCountGetter.count }}</b>
          </div>
          <div v-if="commissionsCountGetter.count_new > 0"
               class="small">
            Новых:&nbsp;<b>{{ commissionsCountGetter.count_new }}</b>
          </div>
          <div v-if="commissionsCountGetter.count_expired > 0"
               class="small">
            Просрочены:&nbsp;<b>{{ commissionsCountGetter.count_expired }}</b>
          </div>
        </div>
      </router-link>
      <router-link class="sidebar-link position-relative"
                   v-if="accessToControls"
                   to="/controls"
                   @click.native.stop
                   data-tooltip-position="bottom"
                   data-tooltip-size="auto"
                   data-tooltip-text-align="left"
                   role="tooltip">
        <controls-icon/>
        <counter-controls-icon-small v-if="isCollapsedState"/>
        <span class="sidebar-link-text" v-if="!isCollapsedState">Контроль</span>
        <menu-counter v-if="!isCollapsedState" getter="controlsCountGetter"/>
        <div class="tooltip-aria-label">
          Контроль
          <div v-if="controlsCountGetter.count > 0"
               class="small">
            Всего:&nbsp;<b>{{ controlsCountGetter.count }}</b>
          </div>
          <div v-if="controlsCountGetter.count_new > 0"
               class="small">
            Новых:&nbsp;<b>{{ controlsCountGetter.count_new }}</b>
          </div>
          <div v-if="controlsCountGetter.count_expired > 0"
               class="small">
            Просрочены:&nbsp;<b>{{ controlsCountGetter.count_expired }}</b>
          </div>
        </div>
      </router-link>
      <router-link class="sidebar-link"
                   v-if="accessToDocuments"
                   to="/search"
                   @click.native.stop
                   aria-label="Поиск документов"
                   data-tooltip-position="bottom"
                   data-tooltip-size="auto"
                   role="tooltip">
        <search-doc-icon/>
        <span class="sidebar-link-text" v-if="!isCollapsedState">Поиск документов</span>
      </router-link>
      <router-link class="sidebar-link"
                   v-if="accessToClients"
                   to="/clients"
                   @click.native.stop
                   aria-label="Контрагенты"
                   data-tooltip-position="bottom"
                   data-tooltip-size="auto"
                   role="tooltip">
        <contractor-icon/>
        <span class="sidebar-link-text" v-if="!isCollapsedState">Контрагенты</span>
      </router-link>

      <a @click.stop="toggleHandler"
         v-if="isExpandableState && !isCollapsedState"
         :class="classToggler">
        <span class="sidebar-link-text">Свернуть</span>
        <collapse-icon/>
      </a>

      <a @click.stop="toggleHandler"
         v-if="isExpandableState && isCollapsedState"
         :class="classToggler"
         aria-label="Развернуть"
         data-tooltip-position="top"
         data-tooltip-size="auto"
         role="tooltip">
        <expand-icon/>
      </a>

      <a @click.stop="collapsedMinimizerHandler"
         v-if="isCollapsedMinimizerAvailable"
         :class="classCollapsedMinimizer">
        <expand-icon/>
      </a>
    </div>
  </div>
</template>

<script>
import Constants from '@/configs/constants';
import GitReleaseData from '@/components/GitReleaseData';
import CommissionsIcon from '@/components/icons/CommissionsIcon.vue';
import MenuCounter from '@/components/widgets/MenuCounter.vue';
import CounterCommissionsIconSmall from '@/components/widgets/CounterCommissionsIconSmall.vue';
import ControlsIcon from '@/components/icons/ControlsIcon.vue';
import CounterControlsIconSmall from '@/components/widgets/CounterControlsIconSmall.vue';
import SearchDocIcon from '@/components/icons/SearchDocIcon.vue';
import ContractorIcon from '@/components/icons/ContractorIcon';
import CollapseIcon from '@/components/icons/CollapseIcon';
import ExpandIcon from '@/components/icons/ExpandIcon';
import {mapGetters, mapMutations} from 'vuex';
import {COUNTERPARTIES_REGIONS_SEGMENTS__VIEW, DOCUMENTS__CONTROLS, DOCUMENTS__VIEW} from '@/configs/events';

export default {
  name: 'SidebarGeneral',
  inject: [
    'app'
  ],
  components: {
    GitReleaseData,
    CommissionsIcon,
    MenuCounter,
    CounterCommissionsIconSmall,
    ControlsIcon,
    CounterControlsIconSmall,
    SearchDocIcon,
    ContractorIcon,
    CollapseIcon,
    ExpandIcon,
  },
  data: () => ({
    isCollapsed: false,
    isCollapsedMinimizer: false,
    clickCount: 0,
    clickDelay: 700,
    clickTimer: null,
  }),
  methods: {
    ...mapMutations(['systemIsCollapsedMutation']),
    ...mapMutations(['systemIsCollapsedMinimizerMutation']),
    init() {
      if (!this.isExpandableState || this.systemIsCollapsedGetter) {
        this.isCollapsed = true;
      }
      if (this.isCollapsedMinimizerAvailable && this.systemIsCollapsedMinimizerGetter) {
        this.isCollapsedMinimizer = true;
      }
    },
    toggleHandler(state = null) {
      if (!this.isExpandableState) {
        return;
      }
      if (state === null || state?.type === 'click') {
        state = !this.isCollapsed;
      }
      this.isCollapsed = !!state;
      this.systemIsCollapsedMutation(this.isCollapsed)
      this.$eventBus.$emit('layout-resize');
    },
    swipeHandler(direction) {
      switch (direction) {
        case 'left':
          if (!this.isCollapsed) {
            this.toggleHandler(true);
          }
          break;
        case 'right':
          if (this.isCollapsed) {
            this.toggleHandler(false);
          }
          break;
      }
    },
    clickHandler() {
      this.clickCount++;
      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0
        }, this.clickDelay);
      } else {
        clearTimeout(this.clickTimer);
        this.toggleHandler();
        this.clickCount = 0;
      }
    },
    collapsedMinimizerHandler(state = null) {
      if (!this.isCollapsedMinimizerAvailable) {
        return;
      }
      if (state === null || state?.type === 'click') {
        state = !this.isCollapsedMinimizer;
      }
      this.isCollapsedMinimizer = !!state;
      this.systemIsCollapsedMinimizerMutation(this.isCollapsedMinimizer)
      this.$eventBus.$emit('layout-resize');
    },
  },
  computed: {
    ...mapGetters([
      'accessToEvent',
      'commissionsCountGetter',
      'controlsCountGetter',
      'systemIsCollapsedGetter',
      'systemIsCollapsedMinimizerGetter',
    ]),
    accessToControls() {
      return this.accessToEvent(DOCUMENTS__CONTROLS);
    },
    accessToDocuments() {
      return this.accessToEvent(DOCUMENTS__VIEW);
    },
    accessToClients() {
      return this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW);
    },
    isCollapsedState() {
      if (!this.isExpandableState) {
        return true;
      }
      return this.isCollapsed;
    },
    isCollapsedMinimizerState() {
      return this.isCollapsedMinimizer;
    },
    isCollapsedMinimizerAvailable() {
      return this.app.appWidth > Constants.resolutionPoints.small
          && this.app.appWidth < Constants.resolutionPoints.medium;
    },
    isExpandableState() {
      return this.app.appWidth >= Constants.resolutionPoints.medium;
    },
    isHeightTiny() {
      return this.app.appHeight <= Constants.resolutionPoints.tiny;
    },
    isHeightBaby() {
      return this.app.appHeight <= Constants.resolutionPoints.baby;
    },
    classWrapper() {
      return {
        'sidebar-wrapper': true,
        'sidebar-collapsed': this.isCollapsedState,
        'sidebar-collapsed-full': this.isCollapsedMinimizerAvailable && this.isCollapsedMinimizerState,
        'sidebar-height-tiny': this.isHeightTiny,
        'sidebar-height-baby': this.isHeightBaby,
      };
    },
    classToggler() {
      return {
        'sidebar-link': true,
        'sidebar-toggler': true,
        'sidebar-toggler-baby': this.isHeightBaby,
      };
    },
    classCollapsedMinimizer() {
      return {
        'sidebar-link': true,
        'sidebar-collapsed-minimizer': true,
        'sidebar-collapsed-minimizer-baby': this.isHeightBaby,
        'expand': !this.isCollapsedMinimizerState,
      };
    },
  },
  beforeMount() {
    this.init();
  },
}
</script>

<style scoped lang="scss">
.sidebar-logo {
  position: relative;
  z-index: 13;
}
</style>
